// Maximum file size to preview (in bytes)
export const maxFileSizeToPreview = 1024000;

// File types
export const fileTypes = {
  DIR: "DIR",
  FILE: "FILE",
};

// Sorting order
export const sortingOrder = {
  ASC: "ASC",
  DES: "DES",
};

// Sorting types
export const sortingTypes = {
  DATE: "date",
  STRING: "string",
  NUMBER: "number",
};

// Files view types
export const filesViewTypes = {
  GRID: "grid",
  MEDIUM_GRID: "medium-grid",
  LARGE_GRID: "large-grid",
  LIST: "list",
};

// Sorting fields
export const sortingFields = {
  NAME: "name",
  SIZE: "size",
  LAST_MODIFIED: "modified",
  KEYWORDS: "keywords",
  ROOT_DIR: "root_dir",
  CREATED: "created",
};

// Image extensions
export const imageExtensions = [
  "apng",
  "bmp",
  "cur",
  "gif",
  "ico",
  "img",
  "jfif",
  "jpe",
  "jpeg",
  "jpg",
  "pjpeg",
  "pjp",
  "png",
  "tiff",
  "svg",
];

// Audio extensions
export const audioExtensions = ["mp3", "ogg", "wav"];

// Video extensions
export const videExtensions = ["m4v", "mp4", "webm"];

// Media types
export const mediaTypes = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
};

// Mime types
export const mimeTypes = {
  "*": "application/octet-stream",
  "3gp": "video/3gp",
  "3gpp": "video/3gpp",
  "3g2": "video/3gpp",
  aif: "audio/x-aiff",
  aifc: "audio/x-aiff",
  aiff: "audio/x-aiff",
  asf: "video/x-ms-asf",
  avi: "video/x-msvideo",
  flv: "video/x-flv",
  gif: "image/gif",
  m4v: "video/m4v",
  mid: "audio/mid",
  mov: "video/quicktime",
  mp2: "video/mpeg",
  mp3: "audio/mp3",
  mp4: "video/mp4",
  mkv: "video/mp4",
  h264: "video/mp4",
  webm: "video/webm",
  mpa: "video/mpeg",
  mpe: "video/mpeg",
  mpeg: "video/mpeg",
  mpg: "video/mpeg",
  mpv2: "video/mpeg",
  swf: "application/x-shockwave-flash",
  wav: "audio/x-wav",
  wmv: "video/x-ms-wmv",
};

// Operation status
export const operationStatus = {
  ONGOING: "ongoing",
  WAITING: "waiting",
  COMPLETED: "completed",
  FAILED: "failed",
  CANCELED: "canceled",
};

// Select file types
export const selectFileTypes = [
  "toggle",
  "checkAll",
  "uncheckAll",
  "checkAllFiles",
  "checkAllFolders",
  "checkItemsWithDot",
  "checkToday",
  "checkWeek",
  "checkMonth",
  "checkLastTwoMonth",
  "checkLastThreeMonth",
];

// Buttons to ignore for basket
export const buttonsToIgnoreForBasket = [
  "addToBasket",
  "createFolder",
  "expandAllItems",
  "doLogout",
  "cut",
  "paste",
  "rename",
  "delete",
  "showBasket",
  "upload",
  "unzip",
  "zipItems",
];

export const defaultThemeColors = {
  accentLight: "#F5B451",
  accentDark: "#F5B451",
  accentSecondaryLight: "#FCEDD8",
  accentSecondaryDark: "#303030",
  backgroundPrimaryLight: "#FFFFFF",
  backgroundPrimaryDark: "#222222",
  backgroundSecondaryLight: "#FFFFFF",
  backgroundSecondaryDark: "#303030",
  textPrimaryLight: "#222222",
  textPrimaryDark: "#FFFFFF",
  textSecondaryLight: "#222222",
  textSecondaryDark: "#222222",
};

export const slideShowAllowedFilesExtensions = [
  "3gp",
  "3gpp",
  "3g2",
  "aif",
  "aifc",
  "aiff",
  "asf",
  "avi",
  "flv",
  "gif",
  "m4v",
  "mid",
  "mov",
  "mp2",
  "mp3",
  "mp4",
  "webm",
  "mpa",
  "mpe",
  "mpeg",
  "mpg",
  "mpv2",
  "swf",
  "wav",
  "wmv",
];

export const videoExtensionsSlideshow = [
  "3gp",
  "3gpp",
  "3g2",
  "asf",
  "avi",
  "flv",
  "m4v",
  "mov",
  "mp4",
  "webm",
  "mpa",
  "mpe",
  "mpeg",
  "mpg",
  "mpv2",
  "swf",
  "wmv",
];

export const audioExtensionsSlideshow = [
  "aif",
  "aifc",
  "aiff",
  "mid",
  "mp2",
  "mp3",
  "mpa",
  "wav",
];

export const defaultFileListingColumnsOrder = {
  name: { order: 0 },
  path: { order: 1 },
  size: { order: 2 },
  modified: { order: 3 },
  created: { order: 4 },
  keywords: { order: 5 },
};

export const editInlineFilesExtensionAllowed = "txt,html,htm,css,js,log,xml";
